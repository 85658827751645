<template>
    <comp-table title="员工管理" :columns="columns" tableApi="WEB://Employee/FindAllByCompany">
        <template #operate>
            <Button type="info" @click="$router.push('/employee/form')">新增</Button>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "@/components/comp-table.vue"
// import Request from "@/module/request/request"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            columns: [
                {
                    title: "用户名",
                    key: "username",
                },
                {
                    title: "手机号码",
                    key: "phone",
                },
                {
                    title: "是否启用",
                    render: (h, params) => {
                        return params.row.enabled ? "已启用" : "未启用"
                    },
                },
                {
                    title: "操作",
                    width: 200,
                    render: (h, params) => {
                        console.log(params)
                        return h(
                            "div",
                            {
                                style: {
                                    display: "flex",
                                    "align-items": "center",
                                },
                            },
                            [
                                h(
                                    "button",
                                    {
                                        class: "ivu-btn ivu-btn-info",
                                        style: {
                                            marginRight: "5px",
                                        },
                                        onClick: () => {
                                            this.$router.push({
                                                path: "/employee/form",
                                                query: {
                                                    id: params.row.uuid,
                                                },
                                            })
                                        },
                                    },
                                    "编辑"
                                ),
                                h(
                                    "button",
                                    {
                                        class: "ivu-btn ivu-btn-error",
                                        style: {
                                            marginRight: "5px",
                                        },
                                        onClick: () => {
                                            this.$Modal.confirm({
                                                title: "删除",
                                                content: "确定要删除吗？",
                                                onOk: () => {
                                                    Request.post("WEB://Employee/Delete", {
                                                        id: params.row.id,
                                                    }).then(() => {
                                                        this.$Message.success("删除成功")
                                                        this.$refs.table.refresh()
                                                    })
                                                },
                                            })
                                        },
                                    },
                                    "删除"
                                ),
                            ]
                        )
                    },
                },
            ],
        }
    },
}
</script>

<style></style>
